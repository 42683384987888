import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import IosPricetag from 'react-ionicons/lib/IosPricetag'
import PostPreview from '../components/PostPreview'
import Layout from '../components/Layout'

const ProjectsPage = ({data}) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <div className="projects-page">

        <div className="all-tags-btn-wrap">
          <Link
            to="/tags/"
            className="btn btn-green">
            <IosPricetag style={{marginRight: 10}} /> see all tags
          </Link>
        </div>

        <h1>Projects</h1>
        <div className="row">
          {posts.map((post, i) => {
            // console.log('Post: ', post.node)
            return (
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" key={i}>
                <PostPreview data={post.node} />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ProjectsPage

export const ProjectsQuery = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
      filter: { frontmatter: { published: { eq: true} } }
      ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            title
            tags
            featuredImage {
              childImageSharp{
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
